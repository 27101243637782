import { IntlShape } from "gatsby-plugin-intl";
import React from "react";

type FooterProps = {
  intl: IntlShape;
};

const Footer: React.FC<FooterProps> = ({ intl }) => {
  return (
    <footer className="footer">
      <p className="footer__content">
        {intl.formatMessage({ id: "footer-created" })}
      </p>
      <p className="footer__content">
        {intl.formatMessage({ id: "footer-author" })}
      </p>
    </footer>
  );
};

export default Footer;
