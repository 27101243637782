import { IntlShape } from "gatsby-plugin-intl";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import { useLocation } from "@reach/router";

import { animationIsOkay, isScrolled } from "../utils/utilFunctions";
// import LangSwitcher from "./Nav/LangSwitcher";
import Logo from "./Nav/Logo";
import MobileMenuBtn from "./Nav/MobileMenuBtn";
import ThemeSwitcher from "./Nav/ThemeSwitcher";
import { AnimatedLink } from "./Nav/AnimatedLink";

type NavProps = {
  intl: IntlShape;
  skillsRef?: React.RefObject<HTMLElement>;
  projectsRef?: React.RefObject<HTMLElement>;
  contactRef?: React.RefObject<HTMLElement>;
};

const Nav: React.FC<NavProps> = ({
  intl,
  skillsRef,
  projectsRef,
  contactRef,
}) => {
  const tl = gsap.timeline();
  const navRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  // FIXME: clean component (functions)

  const getMediaQueryOffset = () => {
    let number = 0;
    if (window.matchMedia("(max-width: 900px)").matches) number = 60;
    return number;
  };

  const handleHome = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    window.scrollTo(0, 0);
    //@ts-ignore
    e.target.blur();
  };

  const handleSkills = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (skillsRef && skillsRef.current) {
      window.scrollTo(0, skillsRef.current.offsetTop - getMediaQueryOffset());
      //@ts-ignore
      e.target.blur();
    }
  };

  const handleProjects = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (projectsRef && projectsRef.current) {
      window.scrollTo(0, projectsRef.current.offsetTop - getMediaQueryOffset());
      //@ts-ignore
      e.target.blur();
    }
  };
  const handleContact = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (contactRef && contactRef.current) {
      window.scrollTo(0, contactRef.current.offsetTop);
      // @ts-ignore
      e.target.blur();
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (!navRef.current) return;
      const scrollCheck = window.pageYOffset > 40;

      if (scrollCheck) {
        navRef.current.classList.add("nav__scrolled");
      } else {
        navRef.current.classList.remove("nav__scrolled");
      }
    });
  }, []);

  useEffect(() => {
    if (!animationIsOkay() || isScrolled()) return;

    if (!window.matchMedia("(min-width: 768px)").matches) return;

    if (location.pathname === "/cs/" || location.pathname === "/en/") {
      tl.from(navRef.current, {
        yPercent: -100,
        duration: 1,
        delay: 1.7,
        ease: "expo.out",
      });
    }
  }, [navRef]);

  return (
    <nav className="nav" ref={navRef}>
      <div className="nav__wrapper">
        <AnimatedLink to={`/`} className="nav__logo-link">
          <Logo />
        </AnimatedLink>
        <div className="nav__list">
          {location.pathname === "/" || location.pathname === "/en/" ? (
            <>
              <button tabIndex={0} className="nav__item" onClick={handleHome}>
                {intl.formatMessage({ id: "nav-home" })}
              </button>
              <button
                tabIndex={0}
                className="nav__item"
                onClick={handleProjects}
              >
                {intl.formatMessage({ id: "nav-projects" })}
              </button>
              <button tabIndex={0} className="nav__item" onClick={handleSkills}>
                {intl.formatMessage({ id: "nav-skills" })}
              </button>
              <button
                tabIndex={0}
                className="nav__item"
                onClick={handleContact}
              >
                {intl.formatMessage({ id: "nav-contact" })}
              </button>
            </>
          ) : (
            <>
              <AnimatedLink to={`/`} className="nav__item">
                {intl.formatMessage({ id: "nav-home" })}
              </AnimatedLink>
              <AnimatedLink to={`/#projects-section`} className="nav__item">
                {intl.formatMessage({ id: "nav-projects" })}
              </AnimatedLink>
              <AnimatedLink to={`/#skills-section`} className="nav__item">
                {intl.formatMessage({ id: "nav-skills" })}
              </AnimatedLink>
              <AnimatedLink to={`/#contact-section`} className="nav__item">
                {intl.formatMessage({ id: "nav-contact" })}
              </AnimatedLink>
            </>
          )}
          <ThemeSwitcher />
          {/* <LangSwitcher intl={intl} /> */}
        </div>
        <MobileMenuBtn />
      </div>
    </nav>
  );
};

export default Nav;
