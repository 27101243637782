import gsap from "gsap";

export const animationIsOkay = () =>
  window.matchMedia("(prefers-reduced-motion: no-preference)").matches;

export const isScrolled = () => (window.pageYOffset > 40 ? true : false);

export const exitAnimation = () => {
  const tl = gsap.timeline({ defaults: { ease: "expo.inOut" } });

  if (!animationIsOkay()) return;

  tl.to(".overflow", {
    yPercent: 100,
    duration: 0,
  })
    .to(".overflow--first", {
      yPercent: 0,
      duration: 0.7,
    })
    .to(
      ".overflow--second",
      {
        yPercent: 0,
        duration: 0.7,
      },
      "0.05"
    )
    .to(
      ".overflow--third",
      {
        yPercent: 0,
        duration: 0.7,
      },
      "0.1"
    );
};
