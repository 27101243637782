import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql as gql } from "gatsby";

type seoProps = {
  description?: string;
  keywords?: string;
  title: string;
  url?: string;
  author?: string;
};

const Seo = ({ description, keywords, title, url, author }: seoProps) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const metaTitle = data.site.siteMetadata.title;
        const metaAuthor = author || data.site.siteMetadata.author;
        const metaUrl = url || data.site.siteMetadata.url;
        const metaKeywords = keywords || data.site.siteMetadata.keywords;
        return (
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
            title={title}
            //FIXME: favicon sizes
            link={[
              {
                rel: "icon",
                type: "image/png",
                href: "/favicon.ico",
              },
            ]}
            titleTemplate={`%s | ${metaTitle}`}
            meta={[
              {
                name: "description",
                content: metaDescription,
              },
              {
                name: "og:title",
                content: title,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `author`,
                content: metaAuthor,
              },
              {
                name: "og:description",
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: "keywords",
                    content: metaKeywords,
                  }
                : []
            )}
          />
        );
      }}
    />
  );
};

const detailsQuery = gql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        url
        description
        author
        keywords
      }
    }
  }
`;

export default Seo;
