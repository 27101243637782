import React from "react";

type contactLinkProps = {
  text: string;
  link: string;
  linkAria: string;
};

const ContactLink: React.FC<contactLinkProps> = ({
  children,
  text,
  link,
  linkAria,
}) => {
  return (
    <div>
      <div className="contact__link-wrapper">
        <p className="contact__link-text">
          {children}
          {text}:{" "}
        </p>
        <a
          href={text === "Email" ? `mailto:${link}` : link}
          target="_blank"
          className="contact__link-link"
          aria-label={linkAria}
        >
          {link}
        </a>
      </div>
      {text === "Twitter" && (
        <p className="contact__link-info">
          (Using only for consuming tech news and DMS)
        </p>
      )}
    </div>
  );
};

export default ContactLink;
