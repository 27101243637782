import React from "react";
import { IntlShape } from "gatsby-plugin-intl";

import { MobileMenuProvider } from "../context/mobileMenu";
import Overflow from "./Overflow";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu/MobileMenu";
import Aside from "./MobileMenu/Aside";
import ScrollTopBtn from "./ScrollTopBtn";
import Footer from "./Footer";

type LayoutProps = {
  skillsRef?: React.RefObject<HTMLElement>;
  projectsRef?: React.RefObject<HTMLElement>;
  contactRef?: React.RefObject<HTMLElement>;
  intl: IntlShape;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  skillsRef,
  projectsRef,
  contactRef,
  intl,
}) => {
  return (
    <>
      <Overflow />
      <MobileMenuProvider>
        <Nav
          intl={intl}
          skillsRef={skillsRef}
          projectsRef={projectsRef}
          contactRef={contactRef}
        />
        <MobileMenu>
          <Aside
            skillsRef={skillsRef}
            projectsRef={projectsRef}
            contactRef={contactRef}
          />
        </MobileMenu>
      </MobileMenuProvider>
      <ScrollTopBtn />
      <main className="container">{children}</main>
      <Footer intl={intl} />
    </>
  );
};

export default Layout;
