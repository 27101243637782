import React, { useContext, useEffect } from "react";
import { MobileMenuContext } from "../../context/mobileMenu";

const MobileMenu: React.FC = ({ children }) => {
  const { mobileMenuOpen, setMobileMenuOpen } = useContext(MobileMenuContext);

  const escFunction = (event: KeyboardEvent) => {
    if (event.code === "Escape") {
      setMobileMenuOpen(false);
    }
  };
  useEffect(() => {
    const body = document.body;
    if (mobileMenuOpen) {
      body.style.overflow = "hidden";
    } else {
      body.removeAttribute("style");
    }
    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [mobileMenuOpen]);
  return (
    <div className="mobile-menu">
      <div
        onClick={() => setMobileMenuOpen(false)}
        className={`mobile-menu-bg ${mobileMenuOpen && "mobile-menu-bg--in"}`}
      ></div>
      {children}
    </div>
  );
};

export default MobileMenu;
