import { IntlShape, useIntl } from "gatsby-plugin-intl";
import React, { useContext } from "react";
import { useLocation } from "@reach/router";

import TransitionLink from "gatsby-plugin-transition-link";
import { MobileMenuContext } from "../../context/mobileMenu";
import LangSwitcher from "../Nav/LangSwitcher";
import ThemeSwitcher from "../Nav/ThemeSwitcher";
import MobileMenuClose from "./MobileMenuClose";
import { exitAnimation } from "../../utils/utilFunctions";
import { AnimatedLink } from "../Nav/AnimatedLink";

type AsideProps = {
  skillsRef?: React.RefObject<HTMLElement>;
  projectsRef?: React.RefObject<HTMLElement>;
  contactRef?: React.RefObject<HTMLElement>;
};

// FIXME: clean component (functions)

const Aside: React.FC<AsideProps> = ({
  skillsRef,
  projectsRef,
  contactRef,
}) => {
  const { mobileMenuOpen, setMobileMenuOpen } = useContext(MobileMenuContext);
  const location = useLocation();

  const getMediaQueryOffset = () => {
    let number = 0;
    if (window.matchMedia("(max-width: 900px)").matches) number = 60;
    return number;
  };

  const handleHome = () => {
    setMobileMenuOpen(false);
    window.scrollTo(0, 0);
  };

  const handleProjects = () => {
    if (projectsRef && projectsRef.current) {
      setMobileMenuOpen(false);
      window.scrollTo(0, projectsRef.current.offsetTop - getMediaQueryOffset());
    }
  };

  const handleSkills = () => {
    if (skillsRef && skillsRef.current) {
      setMobileMenuOpen(false);
      window.scrollTo(0, skillsRef.current.offsetTop - getMediaQueryOffset());
    }
  };

  const handleContact = () => {
    setMobileMenuOpen(false);
    if (contactRef && contactRef.current) {
      window.scrollTo(0, contactRef.current.offsetTop);
    }
  };

  const intl: IntlShape = useIntl();
  return (
    <aside className={`aside ${mobileMenuOpen && "aside--in"}`}>
      <MobileMenuClose />
      <div className="aside__content">
        {location.pathname === "/cs/" || location.pathname === "/en/" ? (
          <>
            <button onClick={handleHome} className="nav__item">
              {intl.formatMessage({ id: "nav-home" })}
            </button>
            <button onClick={handleProjects} className="nav__item">
              {intl.formatMessage({ id: "nav-projects" })}
            </button>
            <button onClick={handleSkills} className="nav__item">
              {intl.formatMessage({ id: "nav-skills" })}
            </button>
            <button onClick={handleContact} className="nav__item">
              {intl.formatMessage({ id: "nav-contact" })}
            </button>
          </>
        ) : (
          <>
            <AnimatedLink to={`/`} className="nav__item">
              {intl.formatMessage({ id: "nav-home" })}
            </AnimatedLink>
            <AnimatedLink to={`/#projects-section`} className="nav__item">
              {intl.formatMessage({ id: "nav-projects" })}
            </AnimatedLink>
            <AnimatedLink to={`/#skills-section`} className="nav__item">
              {intl.formatMessage({ id: "nav-skills" })}
            </AnimatedLink>
            <AnimatedLink to={`/#contact-section`} className="nav__item">
              {intl.formatMessage({ id: "nav-contact" })}
            </AnimatedLink>
          </>
        )}

        <div className="aside__switchers-wrapper">
          <ThemeSwitcher />
          {/* <LangSwitcher intl={intl} /> */}
        </div>
      </div>
    </aside>
  );
};

export default Aside;
