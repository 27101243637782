import React, { useContext } from "react";
import { MobileMenuContext } from "../../context/mobileMenu";

const MobileMenuBtn: React.FC = () => {
  const { mobileMenuOpen, setMobileMenuOpen } = useContext(MobileMenuContext);
  return (
    <span
      className={`switcherBtn switcherBtn--mobile ${
        mobileMenuOpen && "switcherBtn--mobile-hide"
      }`}
      onClick={() => setMobileMenuOpen(true)}
      aria-label="Open mobile menu"
    >
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 448 512"
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        className="switcherBtn__svg"
      >
        <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
      </svg>
    </span>
  );
};

export default MobileMenuBtn;
