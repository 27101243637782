import React from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import { exitAnimation } from "../../utils/utilFunctions";

type AnimatedLinkProps = {
  to: string;
  className?: string;
  title?: string;
};

export const AnimatedLink: React.FC<AnimatedLinkProps> = ({
  children,
  to,
  className,
  title,
}) => {
  return (
    <TransitionLink
      to={to}
      className={className}
      exit={{
        trigger: () => exitAnimation(),
        length: 0.8,
      }}
      entry={{
        delay: 0.6,
      }}
      title={title}
    >
      {children}
    </TransitionLink>
  );
};
