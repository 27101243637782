import React, { useEffect, useRef } from "react";

const ScrollTopBtn = () => {
  const btnRef = useRef<HTMLButtonElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    window.scrollTo(0, 0);
    //@ts-ignore
    e.target.blur();
  };

  const scrollListener = () => {
    if (!btnRef.current) return;
    const scrollCheck = window.pageYOffset > 45;

    if (scrollCheck) {
      btnRef.current.classList.add("scrollTop--in");
    } else {
      btnRef.current.classList.remove("scrollTop--in");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", () => scrollListener());

    return () => document.removeEventListener("scroll", () => scrollListener());
  }, []);
  return (
    <button
      className="scrollTop"
      onClick={handleClick}
      aria-label="Scroll to top"
      ref={btnRef}
    >
      <svg
        className="scrollTop__svg"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
      </svg>
    </button>
  );
};

export default ScrollTopBtn;
