import React from "react";

const Logo: React.FC = () => {
  return (
    <div className="nav__logo">
      <span className="nav__logo--braces">&#123;</span>
      <span>JH</span>
      <span className="nav__logo--braces">&#125;</span>
    </div>
  );
};

export default Logo;
