import React, { useState } from "react";

const MobileMenuContext = React.createContext({
  mobileMenuOpen: false,
  setMobileMenuOpen: (boolean: boolean) => {},
});

const MobileMenuProvider: React.FC = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  return (
    <MobileMenuContext.Provider value={{ mobileMenuOpen, setMobileMenuOpen }}>
      {children}
    </MobileMenuContext.Provider>
  );
};

export { MobileMenuContext, MobileMenuProvider };
