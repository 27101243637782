import { IntlShape } from "gatsby-plugin-intl";
import React from "react";
import ContactLinks from "./Contact/ContactLinks";
import HeaderLinks from "./Header/HeaderLinks";
import Title from "./Shared/Title";

type ContactSectionProps = {
  intl: IntlShape;
  contactRef?: React.RefObject<HTMLElement>;
};

const ContactSection: React.FC<ContactSectionProps> = ({
  intl,
  contactRef,
}) => {
  return (
    <section ref={contactRef} className="contact" id="contact-section">
      <Title>{intl.formatMessage({ id: "nav-contact" })}</Title>
      <div className="contact__wrapper">
        <p className="contact__text">{intl.formatMessage({ id: "contact" })}</p>
        <ContactLinks />
      </div>
    </section>
  );
};

export default ContactSection;
