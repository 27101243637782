import gsap from "gsap";
import React, { useEffect } from "react";
import { animationIsOkay } from "../utils/utilFunctions";
import Logo from "./Nav/Logo";

const Overflow = () => {
  const tl = gsap.timeline({
    defaults: {
      ease: "expo.inOut",
    },
  });

  useEffect(() => {
    if (!animationIsOkay()) {
      tl.to(".overflow", {
        yPercent: -100,
        duration: 0,
      });
    } else {
      tl.to(
        ".overflow--first",
        {
          yPercent: -100,
          duration: 1.5,
        },
        "0.2"
      )
        .to(
          ".overflow--second",
          {
            yPercent: -100,
            duration: 1.5,
          },
          "0.4"
        )
        .to(
          ".overflow--third",
          {
            yPercent: -100,
            duration: 1.5,
          },
          "0.6"
        );
    }
  }, []);

  return (
    <div className="overflow__wrapper">
      <div className="overflow overflow--first" />
      <div className="overflow overflow--second">
        <div className="overflow__logo">
          <Logo />
        </div>
      </div>
      <div className="overflow overflow--third" />
    </div>
  );
};

export default Overflow;
